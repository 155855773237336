/* eslint-disable */
import 'simplelightbox';

export class Product {
  constructor() {
      this.sliderInit();
      this.lightboxInit();
  }

  sliderInit() {
    var galleryThumbs = new Swiper('.slider-pr-nav', {
      spaceBetween: 0,
      slidesPerView: 5,
      loop: false,
      freeMode: true,
      loopedSlides: 5, //looped slides should be the same
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });
    var galleryTop = new Swiper('.slider-pr-for', {
      spaceBetween: 10,
      loop:false,
      loopedSlides: 5, //looped slides should be the same
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: galleryThumbs,
      },
    });
  }

  lightboxInit() {
   $('.slider-pr-for a').simpleLightbox();
  }

}
