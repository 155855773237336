/* eslint-disable */

export class Home {
  constructor() {
      this.initSlider();
  }

  initSlider() {
    new Swiper('#slideshow0', {
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: {
        delay: 5000,
      },
    });
  }
}
