import {Menu} from 'classes/Menu';
import {Category} from 'classes/Category';
import {Product} from 'classes/Product';
import {Home} from 'classes/Home';


$(document).ready(function () {
  new Menu();
  new Category();
  if ($('#product-product').length) {
    new Product();
  }
  new Home();
});
