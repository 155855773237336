export class Category {
  constructor() {
    this.sortList();
    this.sortLimit();
    this.hideDesc();
    this.filterPagination();
  }

  getURLVar(key, url = false) {
    var value = [];
    let link = document.location;
    if (url) {
      link = url;
    }

    var query = String(link).split('?');

    if (query[1]) {
      var part = query[1].split('&');

      for (let i = 0; i < part.length; i++) {
        var data = part[i].split('=');

        if (data[0] && data[1]) {
          value[data[0]] = data[1];
        }
      }

      if (value[key]) {
        return value[key];
      } else {
        return '';
      }
    }
  }

  sortList() {
    let sort = $("#input-sort");
    if (sort.length) {
      sort.selectmenu({
        change: function () {
          location.href = $(this).val()
        }
      });
    }
  }

  sortLimit() {
    let sort = $("#input-limit");
    if (sort.length) {
      sort.selectmenu({
        change: function () {
          location.href = $(this).val()
        }
      });
    }
  }

  hideDesc() {
    let $desc = $('.category_description');
    if ($desc.text().length < 100) {
      $desc.hide();
    }
  }

  filterPagination() {
    let $this = this;
    $('.pagination a').each(function () {
      let new_url = "";
      let href = $(this).attr('href');
      let page = "";
      let bfilter = $this.getURLVar('bfilter');
      let path = location.pathname;
      page = $this.getURLVar('page', href);
      if (bfilter) {
        if (page) {
          new_url = location.origin + path + "?page=" + page + "&bfilter=" + bfilter;
        } else {
          new_url = location.origin + path + "?bfilter=" + bfilter;
        }
        $(this).attr('href', new_url);
      }


    })
  }


}
