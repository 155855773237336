export class Menu {
  constructor() {
    this.hideMenu();
    this.toggleMenu();
    this.mobileMenu();
  }

  hideMenu() {
    let is_menu = document.querySelector('.mebel_menu');
    if (is_menu) {
      $('.mebel_menu-li').each(function () {
        if (!$(this).hasClass('active')) {
          $(this).find('ul').hide();
        }
        if (!$(this).find('ul').length) {
          $(this).find('.fa').remove();
        }
      });
    }
  }

  toggleMenu() {
    $(document).on('click', '.mebel_menu-li i', function () {
          $(this).toggleClass('fa-chevron-down');
          $(this).parent().find('ul').slideToggle();
    })
  }

  mobileMenu() {
    $('.burger').on('click', function () {
      console.log($(this).closest('.list-inline'));
      $(this).parent().find('ul').slideToggle();
    })
  }

}
